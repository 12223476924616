<div style="height: 100vh;">
  <mat-toolbar color="primary" class="mat-elevation-z8">
  <div fxShow="true" fxHide.gt-sm="true" class="sidemenu">
      <a href="#" (click)="sidenav.toggle()"> <mat-icon aria-hidden="false">menu</mat-icon></a>
    </div>
    <span>Demo App</span>

    <div fxShow="true" fxHide.lt-md="true" class="menu">
      <!-- The following menu items will be hidden on both SM and XS screen sizes -->
      <a routerLink="/home" mat-button >Home</a>
      <a routerLink="/customerview" mat-button>Customer View</a>
    </div>
<span class="example-spacer"></span>
    <mat-icon aria-hidden="false" [matMenuTriggerFor]="userMenu" >account_circle</mat-icon>
    <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
         <button mat-menu-item>
              <mat-icon>account_circle</mat-icon>
              <span>My Profile</span>
          </button>

          <button mat-menu-item >
              <mat-icon>exit_to_app</mat-icon>
              <span>Logout</span>
          </button>
      </mat-menu>
  </mat-toolbar>

  <mat-sidenav-container fxFlexFill class="example-container">
    <mat-sidenav #sidenav fxLayout="column">
      <div fxLayout="column">
        <a (click)="sidenav.toggle()" href="#" mat-button>Close</a>
        <a routerLink="/home" mat-button >Home</a>
        <a routerLink="/customerview" mat-button>Customer View</a>
      </div>
    </mat-sidenav>
    <mat-sidenav-content fxFlexFill>

        <router-outlet></router-outlet>


    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
