import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-record',
  templateUrl: './record.component.html',
  styleUrls: ['./record.component.scss']
})
export class RecordComponent implements OnInit {
  element:any;
  constructor(private dialogRef: MatDialogRef<RecordComponent>,
    @Inject(MAT_DIALOG_DATA) data) {

      this.element = data.element;

    }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }
}
